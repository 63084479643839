<template>
    <!--site content starts-->
    <main class="main-container" :class="$route.name == 'deliverable-matrix' ? 'main-container-width' : '' ">
        <div class="inner-wrapper">

            <!-- Sidebar  -->
            <aside id="sidebar" class="sidebar shadow-sm">
                <side-bar></side-bar>
            </aside>

            <!-- Page Content  -->
            <div :id="$route.name != 'deliverable-matrix' ? 'content' : '' "
                 :class="$route.name == 'deliverable-matrix' ? 'matrix-content-block' : '' "
                 class="d-flex flex-column">

                <div class="d-flex align-items-center mb-2">
                    <span type="button" id="sidebarCollapse" class="px-3 py-2 shadow-sm bg-white fs-5 me-2 shadow-sm mb-2">
                        <font-awesome-icon :icon="['fas', `${sidebarToggle.icon}`]"></font-awesome-icon>
                    </span>

                    <nav aria-label="breadcrumb" class="sm-text ps-3">
                        <ol class="breadcrumb mt-3">
                            <li class="breadcrumb-item">
                                <router-link :to="'/dashboard'">Dashboard</router-link>
                            </li>
                            <li class="breadcrumb-item" v-if="$route.meta.backURL">
                                <router-link :to="`${$route.meta.backURL}`">{{ $route.meta.bredCrumbs[0] }} </router-link>
                            </li>
                            <li class="breadcrumb-item" aria-current="page" v-if="$route.meta.bredCrumbs[1]">
                                <a href="#" v-if="Object.keys($route.params).length == 2"
                                   @click.prevent="backToHome">{{ $route.meta.bredCrumbs[1] }} </a>
                                <span v-else>{{ $route.meta.bredCrumbs[1] }}</span>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page" v-else>
                                {{ $route.meta.bredCrumbs[0] }}
                            </li>
                        </ol>
                    </nav>
                </div>

                <!-- CENTER CONTENT -->
                <div class="px-3">
                    <router-view></router-view>
                </div>

                <!-- FOOTER -->
                <bottom-footer/>

                <!-- CONDITIONAL CHAT BOX -->
                <chat-box v-if="$route.path.match('project') && getProjectData.id"></chat-box>

            </div>

        </div>
    </main>
</template>

<script>
import SideBar from "./SideBar";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import BottomFooter from "@/views/layouts/auth/common/BottomFooter";
import {reactive, onMounted, computed} from 'vue'
import ChatBox from "@/views/auth/project/discussion/ChatBox";

export default {
    name: "Index",
    components: {SideBar, BottomFooter, ChatBox},
    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const project_id = route.params.id
        const company = store.getters.getSelectedBoard
        let sidebarToggle = reactive({
            icon: 'chevron-left'
        })
        const getProjectData = computed(() => store.getters.getProjectData);

        if (route.path.match('project')) {
            store.dispatch('getProject', project_id);
            const payload = {
                url: vueConfig.Dashboard.DashboardCheckInvitationEndPoint,
                project_id: project_id,
                company_id: company.id
            }
            store.dispatch('checkProjectInvitationById', payload);
        }
        function backToHome() {
            router.go(-1)
        }
        onMounted(() => {
            document.getElementById('sidebarCollapse').addEventListener('click', function (){
                document.getElementById('sidebar').classList.toggle("active")
                document.getElementById('sidebar').classList.contains("active") ?
                    sidebarToggle.icon = 'chevron-right'
                    : sidebarToggle.icon = 'chevron-left'
            });
        })

        return {
            backToHome, sidebarToggle, getProjectData
        }
    }
}
</script>
